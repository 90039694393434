import styled from '@emotion/styled';
import {
  ModalContainer,
  Container,
  Heading,
  ModalContentText,
  ModalContent,
  ButtonsContainer,
  CTAButton
} from '@styles/modal';
import { mq } from '@styles/breakpoints';
import { useI18NextContext } from '@components/hooks';
import { ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';

/* ***************** *
 *       Types       *
 * ***************** */
export interface RyanairConfirmFlightsModalProps {
  onDismiss: () => void;
}

/* ****************** *
 *       Styles       *
 * ****************** */
const StyledCTAButton: any = styled(CTAButton)({
  [mq.small]: {
    width: '100%'
  }
});

export const RyanairConfirmFlightsModal = ({ onDismiss }: RyanairConfirmFlightsModalProps) => {
  const t: any = useI18NextContext();

  return (
    <ModalContainer>
      <Container>
        <Heading>{t('modal__confirm-flights--title')}</Heading>
        <ModalContent>
          <ModalContentText>{t('modal__confirm-flights--message')}</ModalContentText>
        </ModalContent>
        <ButtonsContainer>
          <StyledCTAButton
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t('modal__confirm-flights--button-label')}
            onClick={onDismiss}
          />
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
};
