import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import React from 'react';
import { BaseModal } from '@components/common/modal';
import { getModalType } from '@state/modal/modalSelectors';
import { RyanairConfirmFlightsModal } from './RyanairConfirmFlightsModal';

export const RyanairConfirmFlightsModalContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const handleOnDismiss = () => dispatch(closeModal());

  return (
    <BaseModal open={modalType === ModalType.RYANAIR_CONFIRM_FLIGHTS_MODAL} onDismiss={handleOnDismiss} hideCloseButton>
      <RyanairConfirmFlightsModal onDismiss={handleOnDismiss} />
    </BaseModal>
  );
};
